import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';
import {PAYD_GRAPHQL_URL} from "./env";
import {setContext} from '@apollo/client/link/context';
import {loadErrorMessages, loadDevMessages} from "@apollo/client/dev";
import {onError} from "@apollo/client/link/error";

const httpLink = new HttpLink({uri: PAYD_GRAPHQL_URL});

const authLink = setContext((_, {headers}) => {
	try {
		const xAuth = localStorage.getItem('x-auth');
		if (xAuth) {
			const parsedXAuth = JSON.parse(xAuth);
			const token = parsedXAuth?.state?.token;
			if (token) {
				return {
					headers: {
						...headers,
						Authorization: `Bearer ${token}`
					}
				}
			}
		}
	} catch (e) {
		//
	}
	return {
		headers: {
			...headers
		}
	};
});

const errorLink = onError(({graphQLErrors, networkError}) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({message, locations, path}) => {
			console.log(`GraphQL error: ${message}`);
			if (message === 'Unauthenticated.' || message === 'Unauthenticated') {
				console.log(`Unauthenticated`)
				window.location.href = process.env.REACT_APP_PAYD_URL + `/login`;
			}
		});
	}
	if (networkError) {
		console.log(`Network error: ${networkError}.`);
	}
});

export const apollo = new ApolloClient({
	cache: new InMemoryCache(),
	link: ApolloLink.from([authLink, errorLink, httpLink]),
	defaultOptions: {
		query: {
			fetchPolicy: 'network-only',
		},
		watchQuery: {
			fetchPolicy: 'network-only',
		},
	},
});


if (process.env.NODE_ENV !== "production") {
	loadDevMessages();
	loadErrorMessages();
}