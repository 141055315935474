import React, {useMemo} from 'react';

function InvoiceLayout({children, test, invoice}) {
	const logoImage = useMemo(() => invoice?.gatewayCode === 'payfort' ? '/images/logo-payd-payfort.png' : '/images/logo.png', [invoice])
	
	return (
		<div className="container relative max-w-3xl px-4 mx-auto flex min-h-full flex-1 flex-col justify-center py-4 md:py-12 sm:px-6 lg:px-8">
			<div className="flex items-center justify-between">
				{invoice && <img
					className="h-16 w-auto mb-4"
					src={logoImage || '/images/logo.png'}
					alt="Payd"
				/>}
				<div>
					{test && <span className="bg-clip-text text-transparent bg-gradient-to-r from-red-500 to-orange-500 font-bold text-md">
						Test Mode
					</span>}
				</div>
			</div>
			{children}
		</div>
	);
}

export default InvoiceLayout;