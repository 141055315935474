import React, {useRef, useState} from 'react';
import AuthLayout from "../../layouts/AuthLayout";
import ButtonLong from "../../components/ButtonLong";
import TextInput from "../../components/TextInput";
import {Link, useParams} from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {toast} from "react-toastify";
import {useMutation} from "react-query";
import {apollo} from "../../utils/apollo";
import {RESET_PASSWORD} from "../../utils/graphql";

function ResetPassword() {
    const {token} = useParams();
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const recaptcha = useRef()

    const updatePasswordMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: RESET_PASSWORD,
                variables: {
                    input: {
                        token,
                        password,
                        confirm_password: confirmPassword
                    }
                },
            });
            return data?.resetPassword;
        },
        onError: ((error) => {
            if (error.graphQLErrors) {
                error.graphQLErrors.map((item) => console.log(item));
            }
        }),
        onSuccess: async (data) => {
            if (data) {
                if (data.message) {
                    toast.success(data.message);
                }
                setTimeout(() => {
                    window.location.href = process.env.REACT_APP_PAYD_URL + `/login`;
                }, 5000)
            } else {
                toast.error('Something went wrong!')
            }
        },
        onSettled: async () => {
            recaptcha?.current?.reset()
        }
    })
    const updatePasswordHandler = async (e) => {
        e.preventDefault();
        const captcha = recaptcha.current?.getValue();
        if (!captcha) {
            toast.error('Please verify the reCAPTCHA!')
            return;
        }
        console.log({
            token,
            password,
            confirmPassword
        })
        updatePasswordMutation.mutate();
    }

    return (
        <AuthLayout title="Update password">
            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                    <form className="space-y-6" action="#" method="POST">
                        <TextInput label="Password" get={password} set={setPassword} id="password" name="password"
                                   type="password"
                                   required={true}/>

                        <TextInput label="Confirm Password" get={confirmPassword} set={setConfirmPassword}
                                   id="confirm_password" name="confirm_password" type="password"
                                   required={true}/>

                        <div className="flex">
                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY}/>
                        </div>
                        <div>
                            <ButtonLong onClick={updatePasswordHandler}
                                        disabled={updatePasswordMutation.isLoading}>Update</ButtonLong>
                        </div>
                    </form>
                </div>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Not a member yet?{' '}
                    <Link to="/register" className="font-semibold leading-6 text-blue-500 hover:text-blue-500">
                        Sign up here!
                    </Link>
                </p>

                <p className="mt-2 text-center text-sm text-gray-500">
                    Already signed up?{' '}
                    <Link to="/login" className="font-semibold leading-6 text-blue-500 hover:text-blue-500">
                        Go ahead and log in.
                    </Link>
                </p>
            </div>
        </AuthLayout>
    );
}

export default ResetPassword;
