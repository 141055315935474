import React, {memo} from 'react';
import {useMutation, useQuery} from "react-query";
import {apollo} from "../utils/apollo";
import {GET_TEST_MODE, SET_TEST_MODE} from "../utils/graphql";
import Toggle from "./Toggle";
import useAuthStore from "../store/auth";

function TestMode() {
	const {testMode, setTestMode} = useAuthStore();
	
	const fetchTestMode = useQuery(
		'test_mode',
		async () => {
			const {data} = await apollo.query({
				query: GET_TEST_MODE,
				variables: {},
			});
			return data?.getTestMode;
		},
		{
			keepPreviousData: false,
			onSettled: (data) => {
				setTestMode(data)
			},
		}
	);
	
	const setTestModeMutation = useMutation({
		mutationFn: async () => {
			const {data} = await apollo.mutate({
				mutation: SET_TEST_MODE,
				variables: {
					enable: !testMode
				},
			});
			return data?.setTestMode;
		},
		onSettled: (data) => {
			setTestMode(data)
		},
	})
	
	// if (fetchTestMode.isLoading || testMode === null) return <></>;
	
	return (
		<div className="relative">
			{(setTestModeMutation.isLoading || fetchTestMode.isLoading) && <div className="absolute w-full top-0 left-0 h-full bg-white/50 z-10"></div>}
			<Toggle get={testMode} set={value => setTestModeMutation.mutate({value})}>Test Mode</Toggle>
		</div>
	);
}

export default memo(TestMode);