import React from 'react';
import {formatNumberFloat} from "../utils/helpers";
import {clsx} from "clsx";

function MoneyHighlight({value, currency, className}) {
	return (
		<span className={clsx(className, "bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-blue-500 font-bold text-xl")}>{formatNumberFloat(value)} {currency || ''}</span>
	);
}

export default MoneyHighlight;