import React, {useMemo} from 'react';
import DashboardLayout from "../../../layouts/DashboardLayout";
import PageHeader from "../../../components/PageHeader";
import {useTranslation} from "react-i18next";
import {GET_DOCS_V1_LIST} from "../../../utils/graphql";
import {apollo} from "../../../utils/apollo";
import {useQuery} from "react-query";
import Loading from "../../../components/Loading";

function DocsV1Index() {
	const {t} = useTranslation();
	// getDocsV1List
	
	const fetchDocs = useQuery('api_docs_v1_list', async () => {
		const {data} = await apollo.query({
			query: GET_DOCS_V1_LIST, variables: {},
		});
		return data?.getDocsV1List || null;
	}, {keepPreviousData: true});
	
	const records = useMemo(() => fetchDocs?.isSuccess ? fetchDocs?.data : null, [fetchDocs]);
	
	return (<DashboardLayout>
		<PageHeader title={t('api_docs.page_title')} description={t('api_docs.page_description')}></PageHeader>
		{fetchDocs.isLoading && <Loading/>}
		{fetchDocs.isSuccess && <div className="mt-2 prose prose-sm w-full max-w-3xl">
			{records?.data?.map(item => {
				return <div key={item.id} className="my-10">
					<h2>
						<span className="bg-clip-text text-transparent bg-gradient-to-r from-green-500 to-blue-500 font-bold text-xl">
							{item.name}
						</span>
					</h2>
					<div dangerouslySetInnerHTML={{__html: item.html}}/>
				</div>
			})}
		</div>}
	</DashboardLayout>);
}

export default DocsV1Index;