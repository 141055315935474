import React from 'react';
import InvoiceLayout from "../layouts/InvoiceLayout";
import {cn} from "../utils/style";
import InvoiceField from "../components/InvoiceField";
import MoneyHighlight from "../components/MoneyHighlight";
import {useTranslation} from "react-i18next";
import InvoiceBanner from "./InvoiceBanner";

function PaymentCallback({invoice, backendMessage}) {
    const {t} = useTranslation();
    const banner = <InvoiceBanner backendMessage={backendMessage} status={invoice?.status}/>;
    const total = <MoneyHighlight value={invoice?.amount}
                                  currency={invoice?.currency?.symbol}
                                  className={cn('!text-2xl')}/>;

    const completed = invoice?.status === 'completed' || false;
    return (<InvoiceLayout>
            <div className="flex flex-col items-center">
                <img
                    src={completed ? "/images/payment-completed.png" : "/images/payment-failed.png"}
                    className={cn("w-20 h-20")}
                    alt={completed ? "Payment Completed" : "Payment Failed"}/>
                {}
                <h1 className="mt-4 mb-8 font-semibold">{completed ? "Payment Completed" : "Payment Failed"}</h1>
                <div className="w-full bg-white shadow p-4 rounded">
                    <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 my-3">
                        <InvoiceField name="" value={banner} className="col-span-12"/>
                        <InvoiceField name="Status" value={invoice?.status} className="col-span-12 sm:col-span-6"/>
                        <InvoiceField name="Paid At" value={invoice?.paidAt} className="col-span-12 sm:col-span-6"
                                      hide={!completed}/>
                        <InvoiceField name="Card Number" value={invoice?.cardNumber}
                                      className="col-span-12 sm:col-span-6" hide={!completed}/>
                        <InvoiceField name="Auth Code" value={invoice?.authCode} className="col-span-12 sm:col-span-6"
                                      hide={!completed}/>
                        <InvoiceField name={t('pay.total')} value={total} className="col-span-12"/>
                    </div>
                </div>
            </div>
        </InvoiceLayout>);
}

export default PaymentCallback;