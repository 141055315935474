import React from 'react';

function ConditionsOfUse() {
	return (
		<div className="whitespace-pre">
			Here is a sample "Conditions of Use" for a payment gateway:
			
			**Payment Gateway Conditions of Use**
			
			1. **Definitions**
			* "Merchant" means the business or individual using the Payment Gateway to process payments.
			* "Payment Gateway" refers to the online platform and services provided by [Company Name] (the "Provider") that enables Merchants to accept and process payments from customers.
			* "Transaction" means any payment processing activity conducted through the Payment Gateway, including but not limited to credit card transactions, debit card transactions, e-check transactions, and other forms of electronic payments.
			
			2. **Use of the Payment Gateway**
			
			The Payment Gateway is provided by the Provider as a service to Merchants for the purpose of facilitating electronic payments. The Merchant agrees to use the Payment Gateway in accordance with these Conditions of Use, as well as any additional guidelines or rules established by the Provider from time to time.
			
			3. **Payment Processing**
			
			The Merchant acknowledges that all payment processing activities conducted through the Payment Gateway are subject to the terms and conditions of this agreement. The Merchant agrees to comply with all applicable laws and regulations relating to the payment processing industry, including but not limited to anti-money laundering (AML) and know your customer (KYC) requirements.
			
			4. **Fees and Charges**
			
			The Provider reserves the right to charge fees and charges for the use of the Payment Gateway, which may include but are not limited to transaction fees, monthly subscription fees, and other ancillary fees. The Merchant agrees to pay all fees and charges associated with using the Payment Gateway in a timely manner.
			
			5. **Security and Confidentiality**
			
			The Provider is committed to maintaining the security and confidentiality of all information transmitted through the Payment Gateway. The Merchant agrees to take all necessary steps to ensure the secure transmission of sensitive payment information, including but not limited to encrypting data and ensuring that all devices and networks used for payment processing are up-to-date with the latest security patches.
			
			6. **Warranties and Representations**
			
			The Merchant represents and warrants that they have the right to use the Payment Gateway and that their use of the Payment Gateway will comply with all applicable laws and regulations. The Provider makes no warranties, express or implied, regarding the accuracy, completeness, or reliability of any information provided through the Payment Gateway.
			
			7. **Indemnification**
			
			The Merchant agrees to indemnify and hold harmless the Provider, its officers, directors, employees, and agents from and against any claims, damages, or expenses arising out of or related to the Merchant's use of the Payment Gateway.
			
			8. **Term and Termination**
			
			This agreement shall remain in effect for as long as the Merchant uses the Payment Gateway. Either party may terminate this agreement at any time by providing written notice to the other party. Upon termination, the Merchant will immediately cease using the Payment Gateway and pay all outstanding fees and charges associated with their use of the Payment Gateway.
			
			9. **Governing Law**
			
			This agreement shall be governed by and construed in accordance with the laws of [State/Country]. Any disputes arising out of or related to this agreement will be resolved through binding arbitration in accordance with the rules of the American Arbitration Association.
			
			10. **Amendments**
			
			The Provider reserves the right to modify these Conditions of Use from time to time, without notice. The Merchant's continued use of the Payment Gateway after any changes are made shall constitute their acceptance of those changes.
			
			By using the Payment Gateway, the Merchant acknowledges that they have read, understood, and agree to be bound by these Conditions of Use.
			
			**Effective Date:** [Date]
			
			**Last Updated:** [Date]
			
			Please note that this is just a sample agreement, and you should consult with legal counsel before implementing your own "Conditions of Use" for your payment gateway.
		</div>
	);
}

export default ConditionsOfUse;